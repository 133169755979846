
























































































































































import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import { InputSetups } from '@/mixins/input-setups'
import Placeholders from '@/mixins/placeholders/placeholders'
import ModuleConfigSettingsWrapper from '@/includes/logic/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleConfigSettings from '@/includes/logic/Modules/components/ModuleConfigSettings.vue'
import KnowledgeBaseHandlerModuleBuilder
  from "@/includes/logic/Modules/modules/modules/KnowledgeBaseHandlerModule/KnowledgeBaseHandlerModuleBuilder";
import { KNOWLEDGE_BASE_TYPES } from "@/includes/logic/TypedChipsData";
import { HandlerModeTypeEnum } from "./types";
import { KnowledgeBaseArticles } from '@/includes/logic/Modules/modules/modules/KnowledgeBaseHandlerModule/KnowledgeBaseHandlerModule'
import GoBackButton from "@/includes/logic/Modules/components/PageTitleButtons/GoBackButton.vue";
import SaveModuleButton from '@/includes/logic/Modules/components/PageTitleButtons/SaveModuleButton.vue'

import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import { SelectOption } from "piramis-base-components/src/logic/types";
import TypedBlackWhiteListInput from 'piramis-base-components/src/components/Pi/fields/TypedBlackWhiteListInput/TypedBlackWhiteListInput.vue'
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import ChipsInput from 'piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import EditorInput from 'piramis-base-components/src/components/Pi/fields/EditorInput/EditorInput.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import NumberInput from 'piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue'
import { IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";
import AddEntityCard from 'piramis-base-components/src/components/AddEntityCard.vue'

import { Component, Mixins, VModel, Watch } from 'vue-property-decorator'

@Component({
  components: {
    PageTitle,
    MultiMessageEditorWithMediaInput,
    TypedBlackWhiteListInput,
    SelectInput,
    ChipsInput,
    EditorInput,
    NumberInput,
    GoBackButton,
    SaveModuleButton,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    EmptyData,
    AddEntityCard
  },
  data() {
    return {
      MediaEditorMode,
      KNOWLEDGE_BASE_TYPES,
    }
  }
})
export default class KnowledgeBaseHandlerModuleView extends Mixins(ModuleBindings, InputSetups, TariffsTagsHelper, Placeholders) {
  @VModel() module!: KnowledgeBaseHandlerModuleBuilder

  frontendArticles: Array<IterableListItem<KnowledgeBaseArticles>> | null = []

  @Watch('frontendArticles', { deep: true })
  onArticlesChange(articles: Array<IterableListItem<KnowledgeBaseArticles>>) {
    this.module.setArticles(articles)
  }

  get handlerModeTypeOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t('knowledge_base_handler_mode_type_all').toString(),
        value: HandlerModeTypeEnum.All
      },
      {
        label: this.$t('knowledge_base_handler_mode_type_admin').toString(),
        value: HandlerModeTypeEnum.Admin
      },
    ]
  }

  addTemplate() {
    if (this.frontendArticles) {
      this.frontendArticles.push(this.module.iterableItemFactory.create(this.module.getArticleTextTemplate()))
    }
  }

  deleteTemplate(guid: string) {
    if (this.frontendArticles) {
      this.frontendArticles = this.frontendArticles.filter(a => a.guid !== guid)
    }
  }

  mounted() {
    this.frontendArticles = this.module.getFrontendArticles()
  }
}
