
































import { InputSetups } from "@/mixins/input-setups";
import ChipsInput from "piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue";

import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue';
import { RawChipItem } from 'piramis-base-components/src/components/TypedChips/typedChips.types';
import TypedChipsSettingMixin from "piramis-base-components/src/components/TypedChips/TypedChipsSettingMixin";

import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {
    ChipsInput,
    TextInput
  }
})
export default class ChipValueSettingView extends Mixins(InputSetups, TypedChipsSettingMixin) {
  @Watch('currentModel', { deep: true })
  onNewModelChange() {
    this.initLocalValue()
  }

  localValue: string | Array<string> | null = null

  initLocalValue() {
    if (this.currentModel?.model === null) {
      this.localValue = []
    } else {
      this.localValue = this.currentModel.model?.value ?? []
    }
  }

  onProcess() {
    if (this.localValue && this.currentModel) {
      let chips: RawChipItem | Array<RawChipItem>

      if (Array.isArray(this.localValue)) {
        chips = this.localValue.map(v => ({
          type: this.currentModel.type,
          value: v.trim()
        }))
      } else {
        chips = {
          type: this.currentModel.type,
          value: this.localValue.trim()
        }
      }

      this.onChipsUpdate(chips)
      this.localValue = null
    }
  }

  created() {
    this.initLocalValue()

    this.processChipsValues = this.onProcess
  }

}
