var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"knowledge-base-handler-module-view"},[_c('page-title',{attrs:{"tags":[].concat( _vm.module.tags() ),"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.pageHelpMessageOrDescription(_vm.module.model)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('editor-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'prefix',
          'hasAnchor': true,
        }
      }}}),_c('editor-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'footer',
          'hasAnchor': true,
        }
      }}})],1),_c('a-card',{staticClass:"mt-5"},[_c('chips-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'force_prefix',
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: [],
            hasFieldButton: true,
            disabledValue: [],
          },
          'disabled': _vm.disabled
        }
      }}}),_c('select-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'handler_mode_type',
          'options': _vm.handlerModeTypeOptions,
          'multiple': false,
          'disabled': _vm.disabled,
          'clearable': false,
          'hasAnchor': true,
        },
      }}})],1),_c('page-title',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_articles_title'),"help-message":_vm.$t('field_articles_help_message'),"subtitle":null}},[_c('a-button',{attrs:{"slot":"custom-button","type":"primary","icon":"plus"},on:{"click":_vm.addTemplate},slot:"custom-button"},[_vm._v(" "+_vm._s(_vm.$t('add'))+" ")])],1),(_vm.frontendArticles && _vm.frontendArticles.length)?[_vm._l((_vm.frontendArticles),function(article,index){return _c('a-card',{key:article.guid,staticClass:"mb-2 last:mb-0",attrs:{"size":"small","title":_vm.$t('articles_item', [ index + 1 ]),"bordered":false}},[_c('a-button',{attrs:{"slot":"extra","icon":"delete","title":_vm.$t('delete'),"type":"danger"},on:{"click":function($event){return _vm.deleteTemplate(article.guid)}},slot:"extra"}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': article.value,
            'key': 'text',
            'placeholders': _vm.BASE_PLACEHOLDERS,
            'targetToUpload': _vm.groupUploadTarget,
            'prefix': ((_vm.module.model.snakeCaseType) + "_"),
            'editorMode': _vm.MediaEditorMode.TelegramBase,
            'base-api-url': 'api.chatkeeper.info/cabinet',
            'availableButtonsTypes': _vm.newDefaultMessageEditorButtons,
            'disabled': _vm.disabled
          },
        }}})],1)}),_c('add-entity-card',{staticClass:"min-h-16",attrs:{"title":_vm.$t('add')},on:{"click":_vm.addTemplate}},[[_vm._v(_vm._s(_vm.$t('add')))]],2)]:_c('empty-data')],2)}
var staticRenderFns = []

export { render, staticRenderFns }